<section class="psa-featured-themes">
    <div class="container palestras">
        <h1 class="psa-featured-themes-title">{{titulo}}</h1>
        <owl-carousel-o [options]="palestrasOptions">
            <ng-template carouselSlide *ngFor="let palestra of palestrasInput">
                <div class="content" (click)="goto(palestra)">
                    <figure class="psa-featured-theme-cover">
                        <img [attr.src]="palestra.photo" alt="{{palestra.nome}}">
                    </figure>
                    <div class="psa-featured-theme-info">
                        <h1>{{palestra.nome}}</h1>
                        <p>{{getMinibio(palestra.descricao)}}</p>
                    </div>
                </div>
            </ng-template>
        </owl-carousel-o>
    </div>
</section>
