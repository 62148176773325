import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationExtras, Router } from '@angular/router';
import { PalestrasService } from 'src/app/services/palestras.service';
import { ProfissionaisService } from 'src/app/services/profissionais.service';
import { AssuntosService } from 'src/app/services/assuntos.service';
import { Meta, Title } from '@angular/platform-browser';
import { DomSanitizer } from '@angular/platform-browser';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { ViewportScroller } from '@angular/common';
import { filter } from 'rxjs/operators';

export interface IArtigo {
  titulo: string,
  artigo: string,
  resumo: string,
  slug: string,
  image_src: string
  tipo?: string
  fonte?: string
  data?: string
  subtitulo?: string
  id_conteudo_tipo?: number
  categoria?: string
  categoria_slug?: string
}

export interface IFormUser {
  inputs: Array<string>
  requireinputs: Array<string>
  textButton: string

  buttonCenter?: boolean
  showModalSuccess?: boolean
  showError?: boolean
  customRDKey?: string
  customOrigemRD?: 'formulario' | 'whatsapp'
  themeKeyRD?: 'ctr_form' | 'edu_corp_form' | 'espec_form' | 'prof_form'
}
interface IPalestra {
  descricao: string;
  id: number;
  id_tema: null | number;
  nome: string;
  palestrante_foto: string;
  palestrante_minibio: string;
  palestrante_nome: string;
  palestrante_slug: string;
  palestrantes_id: number;
  photo: string;
  slug: string;
  subtitulo: string;
  video: string;
}

@Component({
  selector: 'psa-palestras-item',
  templateUrl: './palestras-item.component.html',
  styleUrls: ['./palestras-item.component.css'],
})
export class PalestrasItemComponent implements OnInit {
  loading: boolean = false;

  public isSaNewsLoading: boolean = true;
  public postsBlog: IArtigo[];

  palestraItem: IPalestra;
  assunto: any;
  profissionaisRelacionados: any = [];
  palestras: any = [];
  assuntosRelacionados: any = [];
  profissional: any;
  depoimentos: any = [];

  linkVideo: any;

  // SEO
  title_seo = 'Palestras - Profissionais SA';

  formUser: IFormUser = {
    requireinputs: ['company', 'segmento', 'cep'],
    inputs: ['company', 'descricao', 'segmento', 'cep'],
    textButton: 'Iniciar conversa',
    themeKeyRD: 'ctr_form',
  };

  public carouselOptions: OwlOptions = {
    margin: 40,
    nav: true,
    dots: false,
    navText: [
      "<i class='fa fa-chevron-left'></i>",
      "<i class='fa fa-chevron-right'></i>",
    ],
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 2,
      },
      740: {
        items: 3,
      },
      940: {
        items: 4,
      },
    },
  };

  public casesOptions: OwlOptions = {
    margin: 10,
    nav: false,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 2,
      },
      940: {
        items: 3,
      },
    },
  };

  videoId: string;

  utmParams: string = '?';
  utmParamsObj: any = {};

  constructor(
    private titleService: Title,
    private route: ActivatedRoute,
    private palestrasService: PalestrasService,
    private assuntosService: AssuntosService,
    private profissionaisService: ProfissionaisService,
    private router: Router,
    private sanitizer: DomSanitizer,
    private viewportScroller: ViewportScroller,
    private meta: Meta,
    private title: Title,
  ) {
    this.route.queryParamMap.subscribe(
      (result: any) => {
        if(Object.keys(result.params).length === 0) {
          this.utmParams = '?utm_source=organico';
          this.utmParamsObj = {
            utm_source: 'organico'
          }
        } else {
          this.utmParams = '';
          this.utmParamsObj = {};
        }
      }
    );
  }

  async ngOnInit() {
    // SEO
    this.titleService.setTitle(this.title_seo);
    this.router.events
    .pipe(filter((event) => event instanceof NavigationEnd))
    .subscribe(() => {
      const currentUrl = window.location.href;
      this.meta.updateTag({ property: 'og:url', content: currentUrl });
    });

    const palestraSlug = this.route.snapshot.paramMap.get('slug');

    this.loading = true;
    try {
      const result2 = await this.palestrasService.getPalestraDisponivelBySlug(
        palestraSlug
      );
      this.palestraItem = result2;
      const retornoPalestrante =
        await this.profissionaisService.getProfissionalBySlug(
          this.palestraItem.palestrante_slug
        );

      this.profissional = retornoPalestrante.palestrante;
      JSON.stringify(localStorage.setItem('speakerSlug', this.profissional.slug))

      this.profissionaisRelacionados = retornoPalestrante.relacionados;

      this.getUrlVideo();

      if (retornoPalestrante.depoimentos.length > 0)
        this.depoimentos.push(...retornoPalestrante.depoimentos);
      this.palestras = retornoPalestrante.palestras;

      this.getAssunto(this.palestraItem.id_tema);
      this.loading = false;
    } catch (error) {
      this.loading = false;
    }
  }

  navigateProposal(proposal: any) {
    this.router.navigate(['palestra/', (proposal.slug)], { queryParamsHandling: 'merge', queryParams: this.utmParamsObj});
  }

  public navigateToRelacionadoProfile(slug: string): void {
    // const extras: NavigationExtras = {
    //   state: {
    //     temas_id,
    //   },
    // };

    this.router.navigate(['/palestrante/', slug], { queryParamsHandling: "merge" });
  }

  getAssunto(idAssunto: number) {
    this.assuntosService.getContent({}).subscribe((itens: any) => {
      const item = itens.assuntos.filter((item: any) => idAssunto === item.id);

      if (item.length) {
        const assunto = item[0] || {};
        this.assuntosRelacionados =
          this.assuntosService.getAssuntosRelacionadas(
            assunto['id'],
            itens.assuntos
          );
      }
    });
  }

  getMinibio(text: String) {
    const maxString = 150;
    return text.length > maxString
      ? text.substring(0, maxString - 4) + ' ...'
      : text;
  }

  gotoPerfil(id) {
    this.router.navigate(['/palestrante/', id, this.utmParams ], { queryParamsHandling: "merge" });
  }

  getUrlVideo() {
    if (this.palestraItem?.video) {
      this.linkVideo = this.sanitizer.bypassSecurityTrustResourceUrl(
        `https://www.youtube.com/watch?v=${this.palestraItem.video}`
      );

      this.videoId = this.palestraItem.video.split('/').pop().trim();
    } else if (this.profissional.palestrante_video) {
      this.linkVideo = this.sanitizer.bypassSecurityTrustResourceUrl(
        `https://www.youtube.com/watch?v=${this.profissional.palestrante_video}`
      );

      this.videoId = this.profissional.palestrante_video.split('/').pop().trim();
    } else {
      this.linkVideo = null;
      this.videoId = null;
    }
  }

  goto() {
    this.viewportScroller.scrollToAnchor('orcamentoTop');
  }
}
