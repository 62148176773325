import { Component } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'psa-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  showModal: boolean;

  constructor(
    private metaService: Meta,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private meta: Meta,
    ){
      this.showModal = false;
  }
  title = 'profissionais-sa-site';
  ngOnInit() {
    this.router.events.pipe(
      filter((event: any) => event instanceof NavigationEnd),
    )
      .subscribe(() => {
        const currentUrl = window.location.href;
        this.meta.updateTag({ property: 'og:url', content: currentUrl });
        var route = this.getChild(this.activatedRoute)
        var data = route.snapshot.data;
        if (data.description) {

          this.metaService.updateTag({ name: 'description', content: data.description })
        } else {
          this.metaService.removeTag("name='description'")
        }
      });

      setTimeout(() => {
        this.showModal = true;
      }, 6000)
  }
  getChild(activatedRoute: ActivatedRoute): any {
    if (activatedRoute.firstChild)
      return this.getChild(activatedRoute.firstChild);
    else
      return activatedRoute;
  }

  onClose(event: any) {
    this.showModal = false;
  }
}
