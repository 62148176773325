import { DOCUMENT } from '@angular/common';
import { Component, EventEmitter, Inject, OnInit, Output, Renderer2 } from '@angular/core';
export interface IArtigo {
  titulo: string,
  artigo: string,
  resumo: string,
  slug: string,
  image_src: string
  tipo?: string
  fonte?: string
  data?: string
  subtitulo?: string
  id_conteudo_tipo?: number
  categoria?: string
  categoria_slug?: string
}

export interface IFormUser {
  inputs: Array<string>
  requireinputs: Array<string>
  textButton: string

  buttonCenter?: boolean
  showModalSuccess?: boolean
  showError?: boolean
  customRDKey?: string
  customOrigemRD?: 'formulario' | 'whatsapp'
  themeKeyRD?: 'ctr_form' | 'edu_corp_form' | 'espec_form' | 'prof_form'
}

@Component({
  selector: 'psa-form-hire-speaker-home',
  templateUrl: './form-hire-speaker-home.component.html',
  styleUrls: [
    './form-hire-speaker-home.component.css',
    '../../../assets/css/bootstrap.css',
  ],
})
export class FormHireSpeakerHomeComponent implements OnInit {
    constructor(
    private _renderer2: Renderer2,
    @Inject(DOCUMENT) private _document: Document
  ) {}

  @Output() close: EventEmitter<any> = new EventEmitter();

  ngOnInit(): void {
    let script = this._renderer2.createElement('script');
    script.type = `text/javascript`;
    script.src =
      'https://psa-profissionaissa.activehosted.com/f/embed.php?id=196';
    script.charset = 'utf-8';

    this._renderer2.appendChild(this._document.body, script);
  }

  closeModal() {
    this.close.emit(true);
  }
}
