import { Component, OnInit, HostListener } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { BsModalRef } from 'ngx-bootstrap/modal';
import { MatDialog } from '@angular/material/dialog';
import { WhatsappButtonComponent } from '../whatsapp-button/whatsapp-button.component';

interface ISubMenuItem {
  title: string,
  description: string,
  url: string
}

interface ISubMenu {
  [key: string]: ISubMenuItem[]
}

@Component({
  selector: 'psa-header',
  templateUrl: './header-new.component.html',
  styleUrls: [
    './header.component.css',
    '../../../assets/css/comuns.css',
    '../../../assets/css/index.css',
    '../../../assets/css/bootstrap.css',
    '../../../assets/css/theme-animate.css',
  ]
})
export class HeaderComponent implements OnInit {
  bsModalRef: BsModalRef;
  public link: string;
  public showSearchBar = false;
  public classStyle = 'positivo';
  public backgroundColor = '#363636';
  public footer;
  public menuModileShow = false;
  public hideSubMenu = false;
  public touggle = false;
  public palestras = [];
  public profissionais = [];
  public itemsSearch = {
    palestras: [],
    profissionais: [],
    sanews: [],
  };
  public activeSubMenuIsMobile: boolean = false
  public activeSubMenuTitle: string
  public activeSubMenuItems: ISubMenuItem[] = []
  public itemsSubMenu: ISubMenu = {
    paraEmpresa: [{
      title: 'Especialistas',
      description: 'Conheça aqui os melhores palestrantes, influenciadores, mentores e trainers para sua empresa. Filtre mais de 1000 profissionais, renomados nacionalmente ou especialistas de diversas áreas.',
      url: 'especialistas'
    }, {
      title: 'Palestras',
      description: 'Aqui você encontra temas de palestras já formatadas para sua empresa gerar conteúdo relevante para sua ação ou evento. Alto impacto para sua marca se destacar e gerar resultados entre clientes e colaboradores.',
      url: 'palestras'
    }, {
      title: 'Calculadora de T&D',
      description: 'A calculadora ideal para você simular em 3 passos qual o custo médio de investimento em Educação corporativa na sua empresa, com palestras, treinamentos, mentorias e consultorias.',
      url: 'calculadora/passo1'
    }, {
      title: 'Educação corporativa',
      description: 'Sua equipe com as 10 competências do Profissional do Futuro, de forma acessível e prática. Técnicas de grandes especialistas para trabalhar de forma contínua a evolução técnica e comportamental da sua equipe.',
      url: 'educacao-corporativa'
    }],
    paraVoce: [{
      title: 'Agenda de eventos',
      description: 'Acesse palestras e treinamentos em todo o Brasil, ministrados por profissionais consagrados e pelo maiores especialistas em diversos assuntos. Potencialize suas habilidades e sua carreira.',
      url: 'para-voce-agenda'
    },
    {
      title: 'Planos de assinatura',
      description: 'Para profissionais que buscam crescimento na carreira e potencializar suas soft skills, praticando com as técnicas dos melhores especialistas de diversas áreas. Seja um profissional de destaque na sua empresa e no mercado.',
      url: 'planos'
    },
    {
      title: 'Seja um palestrante',
      description: 'Profissionalize sua carreira de palestrante e potencialize suas oportunidades de negócios com palestras, treinamentos e mentorias; Tenha acesso a conteúdos exclusivos que trazem dados estratégicos e tendências para quem deseja ser um palestrante de alto impacto.',
      url: 'cadastro/palestrante'
    }]
  }

  logado: boolean;
  usuarioNome: string;

  utmParams: string = '?';
  externalLinks = {
    logo: 'https://profissionaissa.com/palestrantes-e-especialistas',
    base: 'https://profissionaissa.com/palestrantes-e-especialistas',
    education: 'https://profissionaissa.com/educacao-corporativa/',
    news: 'https://profissionaissa.com/psa-news',
  }

  public itemsMenu = [
    { id: 1, text: 'Para empresas', active: false, showSubMenu: true },
    { id: 2, text: 'Para você', active: false, showSubMenu: true },
    { id: 3, text: 'SA NEWS', active: false, href: 'https://blog.profissionaissa.com/palestras/contratar-palestrante/' },
    { id: 4, text: 'O que fazemos', href: 'https://profissionaissa.com/quem-somos', active: false },
  ]

  whiteHeaderRoutes: Array<string> = ['/', '/aplicativos', '/universidade-corporativa', '/educacao-corporativa', '/calculadora/passo1', '/calculadora/passo2', '/calculadora/passo3', '/live/online', '/planos', '/cursos']

  withBackBtnRouter: Array<string> = ['/palestra/'];
  speakerSlug: string = '';

  constructor(
    private router: Router,
    public dialog: MatDialog,
    private aRoute: ActivatedRoute,
  ) {
    this.aRoute.queryParamMap.subscribe(
      (result: any) => {
        for (let key in result.params) {
          this.utmParams = this.utmParams + key + '=' + result.params[key] + '&';

          this.externalLinks = {
            logo: `https://profissionaissa.com/palestrantes-e-especialistas${this.utmParams}`,
            base: `https://profissionaissa.com/palestrantes-e-especialistas${this.utmParams}`,
            education: `https://profissionaissa.com/educacao-corporativa${this.utmParams}`,
            news: `https://profissionaissa.com/psa-news${this.utmParams}`,
          }
        }

        if(Object.keys(result.params).length === 0) {
          this.utmParams = '?utm_source=organico';

          this.externalLinks = {
            logo: `https://profissionaissa.com/palestrantes-e-especialistas${this.utmParams}`,
            base: `https://profissionaissa.com/palestrantes-e-especialistas${this.utmParams}`,
            education: `https://profissionaissa.com/educacao-corporativa${this.utmParams}`,
            news: `https://profissionaissa.com/psa-news${this.utmParams}`,
          }

          this.utmParams = '?';
        }
      }
    );

  }

  ngOnInit(): void {
    this.router.events.subscribe(evt => {
      if (evt instanceof NavigationEnd) {
        this.link = evt.url;
      }
    });
  }

  isApp(): boolean {
    return this.link === '/aplicativo'
  }

  public shouldBeWhite(): boolean {
    if (this.whiteHeaderRoutes.includes(window.location.pathname)) {
      return true
    }
    return false
  }

  showBackBtn(): boolean {
   const location = window.location.toString().split('/');
   return location.includes('palestra')
  }

  showSubMenu() {
    const item = this.itemsMenu.filter(item => item.active && item.showSubMenu);
    return item.length > 0
  }

  openSubNavMenu(key: string, isMobile: boolean = false) {
    if (key === 'paraEmpresa') {
      this.activeSubMenuTitle = "Para Empresa"
    } else {
      this.activeSubMenuTitle = "Para Você"
    }
    this.activeSubMenuIsMobile = isMobile
    this.activeSubMenuItems = [...this.itemsSubMenu[key]]
  }

  closeSubNavMenu() {
    this.activeSubMenuItems = []
  }

  makeBudget(): void {
  }

  openWppModal(): void {
    this.dialog.open(WhatsappButtonComponent, {
      panelClass: 'custom-dialog-container',
      width: '375px',
      height: '592px'
    });
  }

  redirect(): void {
    window.open(`https://profissionaissa.com/contato${this.utmParams}`, '_blank');
  }

  goBackProfile(): void {
    this.speakerSlug = localStorage.getItem('speakerSlug');

    window.open('https://site.profissionaissa.com/palestrante/' + this.speakerSlug + this.utmParams);
  }
}

