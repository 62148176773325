<h2 class="text-center">Depoimentos</h2>
<owl-carousel-o [options]="customOptionsDepoimentos">
  <ng-container *ngFor="let depoimento of depoimentos">
    <ng-template carouselSlide>
      <div class="psa-showcase-depoimento">
        <div>
          <div class="depoimento">
            <p>"{{depoimento.descricao}}"</p>
          </div>
          <div class="d-flex justify-content-center">
            <div class="cliente text-center">
              <h5>{{depoimento.empresa}}</h5>
              <p>{{getNome(depoimento.nome)}}</p>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </ng-container>
</owl-carousel-o>
