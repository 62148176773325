import { Component, OnInit } from '@angular/core';
import { ViewportScroller } from '@angular/common';

@Component({
  selector: 'psa-orcar-buttom',
  template: `
    <div class="filter fixedbutton" (click)="goto()">
        <div>Orçar</div>
    </div>`,
  styleUrls: ['./orcar-button.component.css']
})
export class OrcarButtomComponent implements OnInit {

  constructor(private viewportScroller: ViewportScroller) {}

  ngOnInit(): void {
  }

  goto() {
    this.viewportScroller.scrollToAnchor('orcamento');
  }

}
