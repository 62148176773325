import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: 'psa-asked-questions',
  templateUrl: './asked-questions.component.html',
  styleUrls: [ './asked-questions.component.scss'],
})
export class AskedQuestionsComponent implements OnInit {
  @Input('palestrante') speaker: any;
  isCollapsed = false;
  isCollapsed2 = false;
  isCollapsed3 = false;
  isCollapsed4 = false;

  constructor () {}

  ngOnInit(): void {
  }


  collapse(wichOne: number): void {
    switch(wichOne) {
      case 1:
          this.isCollapsed = !this.isCollapsed;
          this.isCollapsed2 = false;
          this.isCollapsed3 = false;
          this.isCollapsed4 = false;
          break;

      case 2:
          this.isCollapsed2 = !this.isCollapsed2;
          this.isCollapsed = false;
          this.isCollapsed3 = false;
          this.isCollapsed4 = false;
          break;

      case 3:
          this.isCollapsed3 = !this.isCollapsed3;
          this.isCollapsed2 = false;
          this.isCollapsed = false;
          this.isCollapsed4 = false;
          break;

      case 4:
          this.isCollapsed4 = !this.isCollapsed4;
          this.isCollapsed2 = false;
          this.isCollapsed3 = false;
          this.isCollapsed = false;
          break;

      default:
        this.isCollapsed = false;
        this.isCollapsed2 = false;
        this.isCollapsed3 = false;
        this.isCollapsed4 = false;
    }
  }
}
