import { BrowserModule, Meta } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';

import { SafeHtmlPipe } from './pipes/safe-html';
import { HttpClientModule } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { NgxMaskModule, IConfig } from 'ngx-mask';

import { HeaderComponent } from './shared/header/header.component';
import { NotImplementComponent } from './shared/not-implement/not-implement.component';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ProfissionaisPerfilComponent } from './site/profissionais-perfil/profissionais-perfil.component';
import { PalestrasCarouselComponent } from './site/palestras-carousel/palestras-carousel.component';
import { PalestrasItemComponent } from './site/palestras-item/palestras-item.component';
import { DepoimentosComponent } from './shared/depoimentos/depoimentos.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { LOCALE_ID } from '@angular/core';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { FormHireSpeakerComponent } from './site/form-hire-speaker/form-hire-speaker.component';
import { FormHireSpeakerHomeComponent } from './site/form-hire-speaker-home/form-hire-speaker-home.component';
import { FooterWhiteComponent } from './shared/footer-white/footer-white.component';
import { NgOptimizedImage } from '@angular/common';
import { ModalModule } from 'ngx-bootstrap/modal';
import { RelacionadoCarouselComponent } from './shared/relacionados-carousel/relacionados-carousel.component';
import { OrcarButtomComponent } from './shared/orcar-button/orcar-button.component';
import { FACEBOOK_PIXEL_PROVIDER } from './services/pixelService/facebook-pixel.provider';
import { WhatsappButtonComponent } from './shared/whatsapp-button/whatsapp-button.component';
import { MAT_DIALOG_DEFAULT_OPTIONS, MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormHireSpeakerTopComponent } from './site/form-hire-speaker-top/form-hire-speaker-top.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import { AskedQuestionsComponent } from './shared/asked-questions/asked-questions.component';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { FormHireSpeakerBottomComponent } from './site/form-hire-speaker-bottom/form-hire-speaker-bottom.component';
import { HomeComponent } from './site/home/home.component';
import { SpeakersListComponent } from './shared/speakers-list/speakers-list.component';

const maskConfig: Partial<IConfig> = {
  validation: false,
};

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    NotImplementComponent,
    ProfissionaisPerfilComponent,
    PalestrasCarouselComponent,
    PalestrasItemComponent,
    DepoimentosComponent,
    SafeHtmlPipe,
    FormHireSpeakerComponent,
    FormHireSpeakerHomeComponent,
    FooterWhiteComponent,
    HeaderComponent,
    FooterWhiteComponent,
    DepoimentosComponent,
    RelacionadoCarouselComponent,
    OrcarButtomComponent,
    WhatsappButtonComponent,
    FormHireSpeakerTopComponent,
    AskedQuestionsComponent,
    FormHireSpeakerBottomComponent,
    HomeComponent,
    SpeakersListComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    PdfViewerModule,
    CarouselModule,
    ReactiveFormsModule,
    AccordionModule.forRoot(),
    NgOptimizedImage,
    ToastrModule.forRoot({
      timeOut: 10000,
    }),
    ModalModule.forRoot(),
    NgxMaskModule.forRoot(maskConfig),
    MatDialogModule,
    FormsModule,
    // FormControl,
    MatInputModule,
    MatFormFieldModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    CollapseModule.forRoot(),
  ],
  providers: [
    FACEBOOK_PIXEL_PROVIDER,
    Meta,
    { provide: LOCALE_ID, useValue: 'pt-BR' },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
