import { Component, OnInit, Input } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'psa-palestras-carousel',
  templateUrl: './palestras-carousel.component.html',
  styleUrls: ['./palestras-carousel.component.css']
})
export class PalestrasCarouselComponent implements OnInit {
  public palestras;
  @Input() palestrasInput;
  @Input() titulo: string;

  palestrasOptions: OwlOptions = {
    margin: 10,
    nav:false,
    navText: ['', ''],
    responsive: {
        0:{
            items:1
        },
        400:{
            items:2
        },
        940:{
            items:4
        }
    }
  }

  utmParams: any = {};

  constructor(
    private router: Router,
    private activedRoute: ActivatedRoute,
  ) {
    this.activedRoute.queryParamMap.subscribe(
      (result: any) => {
        if (Object.keys(result.params).length === 0) {
          this.utmParams = {
            utm_source: 'organico',
          }
        } else {
          this.utmParams = {};
        }
      }
    )
  }

  ngOnInit(): void {
  }

  getMinibio(text: String) {
    const maxString = 150
    return text.length > maxString ? text.substring(0, (maxString - 4)) + ' ...' : text;
  }

  goto(palestra) {
    this.router.navigate(['/palestra', palestra.slug], { queryParamsHandling: "merge", queryParams: this.utmParams });
  }
}
