import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { ActiveCampaignService } from 'src/app/services/activeCampaign.service';
import { DOCUMENT } from '@angular/common';


@Component({
  selector: 'psa-whatsapp-button',
  templateUrl: './whatsapp-button.component.html',
  styleUrls: ['./whatsapp-button.component.css']
})
export class WhatsappButtonComponent implements OnInit {
  contactInfo: any = {
    email: '',
    name: '',
    phone: '',

  };

  contactForm: FormGroup;

  disableBtn: boolean = true;

  utms: any = {};

  constructor(
    public dialogRef: MatDialogRef<WhatsappButtonComponent>,
    @Inject(MAT_DIALOG_DATA) data: any,
    private activeService: ActiveCampaignService,
    private activatedRoute: ActivatedRoute,
    private _renderer2: Renderer2,
    @Inject(DOCUMENT) private _document: Document
  ) {  }

  ngOnInit(): void {
    let script = this._renderer2.createElement('script');
    script.type = `text/javascript`;
    script.src =
      'https://psa-profissionaissa.activehosted.com/f/embed.php?id=244';
    script.charset = 'utf-8';

    this._renderer2.appendChild(this._document.body, script);
    this.createForm();
    this.getUTMParams();
  }

  getUTMParams(): void {
    this.activatedRoute.queryParamMap.subscribe(
      (result: any) => {
        if(!result.params.utm_source) {
          this.utms = {
            utm_source: 'organic',
          }
        } else {
          this.utms = result.params;
        }
      }
    )
  }

  createForm(): void {
    this.contactForm = new FormGroup({
      name: new FormControl('', Validators.required),
      email: new FormControl('', Validators.required),
      phone: new FormControl('', [Validators.required, Validators.minLength(11)]),
    });
  }

  getErrorMessage(): string {
    if (this.contactForm.controls.phone.hasError('length')) {
      return 'Telefone incorreto';
    }

    return 'Preencha todos os campos corretamente';
  }

  cleanPhoneNumber(phone: string): string {
    const clear = phone.replace(/[^a-zA-Z0-9 ]/g, "");
    return clear;
  }

  validateForms(): void {
    const isEmailValid = this.contactInfo.email && this.contactInfo.email.length > 0;
    const isNameValid = this.contactInfo.name && this.contactInfo.name.length > 0;
    let isPhoneValid = false;

    this.contactInfo.phone = this.cleanPhoneNumber(this.contactInfo.phone);
    if (this.contactInfo.phone.length !== 11) {
      isPhoneValid = false;
    } else {
      isPhoneValid = true;
    }

    if(isEmailValid && isNameValid) {
      if (isPhoneValid) {
        this.disableBtn = false;
      } else {
        this.disableBtn = true;
      }
    } else {
      this.disableBtn = true;
    }
  }

  async sendLead(): Promise<void> {
    if (!this.contactForm.invalid) {
      (await this.activeService.sendLead(this.contactForm.value, this.utms)).subscribe(
        (result: any) => {
          if(result.ok) {
            this.openWhatsApp();
          }
        }
      );
    }
  }

  openWhatsApp(): void {
    window.location.replace('https://api.whatsapp.com/send/?phone=5511964039325&text=Ol%C3%A1%21+Acabo+de+me+cadastrar+no+site+da+PSA.+Gostaria+de+mais+informa%C3%A7%C3%B5es+sobre+palestrantes+e+especialistas+para+eventos%2C+treinamentos+e+outros+conte%C3%BAdos.&type=phone_number&app_absent=0');
  }

}
