import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AbstractService } from './abstract.service';
import { environment } from 'src/environments/environment';

export interface IGetEspecialistasParams {
  id_tema?: string,
  tema?: string,
  investimento?: string,
  formato?: string,
  pesquisa?: string,
  regiao?: string,
  empresa?: number,
  page?: number
}

@Injectable({
  providedIn: 'root'
})

export class ProfissionaisService extends AbstractService {
  retorno = {};
  apiUrl = 'https://api-v2.profissionaissa.com/';
  constructor(public http: HttpClient) {
    super(http, new HttpHeaders({'action': 'Search'}));
  }

  normalize(profissionais) {
    // Normatização dados campo assunto
    profissionais.map(p => {
      if (p.assuntos.length && typeof p.assuntos[0] === 'object') {
        p.assuntos = p.assuntos.map(item => Object.values(item)[0])
      }
      if (p.palestrantes_id) {
        p.id = parseInt(p.palestrantes_id);
      }
      if(p.preco) {
        const tmp = p.preco.split('-');
        p.precoMin = parseInt(tmp[0]);
        p.precoMax = parseInt(tmp[1]);
      }
    });

    return profissionais;
  }

  async getEspecialistas(params?: IGetEspecialistasParams): Promise<any> {
    if (!params.page) params.page = 0
    params.empresa = 4
    const body = Object.assign(params)
    body['empresa'] = 4
    const res = await this.http.post<any>(`${environment.API_V2_URL}base/front/especialistas`, body).toPromise();
    return res.body
  }

  async getProfissionalBySlug(slug: string): Promise<any> {
    let empresa = 4
    const res = await this.http.get<any>(`${environment.API_V2_URL}base/front/especialista/${empresa}/${slug}`).toPromise();
    return res.body
  }


  getAssuntos(cb) {
    fetch(this.apiUrl + 'base/front/principaisTemas',
      {
        method: "GET",
        headers: {
          Accept: 'application/json',
        },
        body: JSON.parse(null)
      }).then(async res => {
        let result = await res.json()
        cb(result.body)
      })
  }

  getPalestras(cb) {
    fetch(this.apiUrl + 'base/front/proximasPalestras',
      {
        method: "GET",
        headers: {
          Accept: 'application/json',
        },
        body: JSON.parse(null)
      }).then(async res => {
        let result = await res.json()
        cb(result.body)
      })
  }

  getPalestrantes(cb) {
    fetch(this.apiUrl + 'base/front/palestrantesMais',
      {
        method: "GET",
        headers: {
          Accept: 'application/json',
        },
        body: JSON.parse(null)
      }).then(async res => {
        let result = await res.json()
        cb(result.body)
      })
  }

  searchSpeakers(filter: string) {
    return this.http.get(`${environment.API_V2_URL}proposal/filter/term?term=${filter}&format=1`)
  }

  savePesquisa(value: string) {
    const body = { value };
    return this.http.post(`${environment.API_V2_URL}site/pesquisas`, body);
  }
}
