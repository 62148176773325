import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ProfissionaisPerfilComponent } from './site/profissionais-perfil/profissionais-perfil.component';
import { PalestrasItemComponent } from './site/palestras-item/palestras-item.component';
import { HomeComponent } from './site/home/home.component';

const routes: Routes = [
  {path: '/', component: HomeComponent },
  {path: 'palestrante/:id', component: ProfissionaisPerfilComponent},
  {path: 'palestra/:slug', component: PalestrasItemComponent},
  {path: 'palestrante/:palestrante/palestra/:slug', component: PalestrasItemComponent},
  {path: '**', component: HomeComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'enabled',
    onSameUrlNavigation: 'reload'
})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
