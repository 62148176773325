<div class="main__container">
  <div class="left">
    <h2>Perguntas Frequentes</h2>
  </div>

  <div class="right">
      <div class="collapse__button" (click)="collapse(1)">
        Quanto custa uma palestra de {{ speaker.nome_palestrante }}?
        <img style="float: right" [width]="20" *ngIf="!isCollapsed" src="../../../assets/icons/collapse-icon-down.svg" />
        <img style="float: right" [width]="20" *ngIf="isCollapsed" src="../../../assets/icons/collapse-icon-up.svg" />
        <div class="collapse__content" *ngIf="isCollapsed">
          <p>O valor de uma palestra pode variar dependendo de diversos fatores. Para chegar ao preço final, consideramos as necessidades do público contratante, o formato do evento e a região onde acontecerá. Além disso, o tamanho e perfil do público também influenciam no valor final da palestra.</p>
          <p>Por isso, todos orçamentos de palestra da PSA são personalizados e detalhados: consideramos todas as variáveis que envolvem um evento, e garantimos uma proposta que atenta a todas as necessidades de cada cliente.</p>
        </div>
      </div>

      <div class="collapse__button" (click)="collapse(2)">
        Como contratar uma palestra de {{ speaker.nome_palestrante }}?
        <img style="float: right" [width]="20" *ngIf="!isCollapsed2" src="../../../assets/icons/collapse-icon-down.svg" />
        <img style="float: right" [width]="20" *ngIf="isCollapsed2" src="../../../assets/icons/collapse-icon-up.svg" />
        <div class="collapse__content" *ngIf="isCollapsed2">
          <p>Basta entrar em contato conosco, a empresa líder do mercado de palestras! Para solicitar um orçamento personalizado, entre em contato com nosso time preenchendo o formulário abaixo. Aqui, nós garantimos: vamos te ajudar a tornar essa palestra um momento inesquecível!</p>
        </div>
      </div>

      <div class="collapse__button" (click)="collapse(3)">
        Posso contratar uma palestra online?
        <img style="float: right" [width]="20" *ngIf="!isCollapsed3" src="../../../assets/icons/collapse-icon-down.svg" />
        <img style="float: right" [width]="20" *ngIf="isCollapsed3" src="../../../assets/icons/collapse-icon-up.svg" />
        <div class="collapse__content" *ngIf="isCollapsed3">
          <p>Sim, sua empresa pode contratar uma palestra online!</p>
          <p>Uma palestra online possui o mesmo o mesmo conteúdo que as palestras presenciais. Além disso, é um jeito prático e econômico de viabilizar um evento de sucesso!</p>
          <p>Nesse sentido, garantimos a melhor conexão do palestrante contratado para que a experiência seja enriquecedora.</p>
        </div>
      </div>

      <div class="collapse__button" (click)="collapse(4)">
        Por que contratar {{ speaker.nome_palestrante }} com a PSA?
        <img style="float: right" [width]="20" *ngIf="!isCollapsed4" src="../../../assets/icons/collapse-icon-down.svg" />
        <img style="float: right" [width]="20" *ngIf="isCollapsed4" src="../../../assets/icons/collapse-icon-up.svg" />
        <div class="collapse__content" *ngIf="isCollapsed4">
          <p>Contratando na PSA, você não precisa se preocupar com:‌</p>
          <ul>
            <li>
              <p><b>Demora na resposta de dúvidas: </b>nosso time fica 100% online e jamais ficará mais do que 1 hora sem retornar.</p>
            </li>
            <li>
              <p><b>Atrasos: </b>o palestrante contratado não vai se atrasar! Aqui, somos rigorosos em contratos.</p>
            </li>
            <li>
              <p><b>Palestrante fugindo do tema: </b>nosso call de briefing é gravado, o que garante que o palestrante não vai fugir do tema central do seu evento.</p>
            </li>
            <li>
              <p><b>Imprevistos ou doenças de última hora: </b>em caso de doença do palestrante, temos especialistas do mesmo calibre de sobreaviso.</p>
            </li>
            <li>
              <p><b>Burocracias e logística: </b>nosso time de CS conhece todos os processos burocráticos de empresas! Definitivamente, contrato e logística não é problema pra nós. Nosso NPS foi 94,35 do último semestre.</p>
            </li>
            <li>
              <p><b>Falta de opções para o seu evento: </b>temos mais de 16 mil palestrantes e sabemos todos os pontos fortes e de atenção de cada um</p>
            </li>
          </ul>
        </div>
      </div>
  </div>
</div>
