<main>
  <div class="fake__header"></div>
  <div class="forms__container">
    <h1>Continue buscando palestrantes em nossa base</h1>
    <div class="input__container">
      <input [(ngModel)]="searchInput" class="search__input" type="text" placeholder="Nome do palestrante ou tema" />
      <button type="button" class="search__button" (click)="searchSpeakers()">Buscar</button>
    </div>
  </div>
  <div class="speakers__container">
    <h3>Resultados da Pesquisa</h3>
    <psa-speakers-list *ngIf="!loading" [speakers]="speakers"></psa-speakers-list>
  </div>
</main>
