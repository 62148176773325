import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { AbstractService } from './abstract.service';
import { environment } from 'src/environments/environment';

const {API_URL, API_V2_URL}  = environment
const  HEADERS = (new HttpHeaders()).set('Content-type', 'application/json')
@Injectable({
  providedIn: 'root'
})
export class PalestrasService extends AbstractService {

  retorno: any = {};
  constructor(public http: HttpClient) {
    super(http, new HttpHeaders({ 'action': 'SiteGetPalestras' }));
  }

  async setSavedToken(token){
    window["localStorage"].setItem('key', JSON.stringify(token));
  }

  public async GetArtigoBySlug(slug: string) {
    let body = JSON.stringify({ slug })

    // const res = await fetch(this.endpoint, {
    const res = await fetch(environment.API_URL, {
      method: "POST",
      headers: {
        Accept: 'application/json',
        action: 'GetArtigo',
        'Content-Type': 'application/json'
      },
      body
    })

    this.retorno = res.json();

    return await (this.retorno);
  }

  public async GetArtigosPorTipo(tipo: number, page: number) {
    let body = JSON.stringify({tipo, page})

    const res = await fetch(environment.API_URL, {
      method: "POST",
      headers: {
        Accept: 'application/json',
        action: 'GetArtigosPorTipo',
        'Content-Type': 'application/json'
      },
      body
    })

    this.retorno = res.json();

    return await (this.retorno);
  }

  async getPalestraById(id) {
    let body = JSON.stringify({ id_palestra: id });

    const res = await fetch(environment.API_URL, {
      method: "POST",
      headers: {
        Accept: 'application/json',
        action: 'GetPalestraById',
        'Content-Type': 'application/json',
        token: null
      },
      body: body
    });
    this.retorno = res.json();

    return await (this.retorno);
  }

async getPalestranteById(id) {
  let body = JSON.stringify({ id:id });

  const res = await fetch(environment.API_URL, {
    method: "POST",
    headers: {
      Accept: 'application/json',
      action: 'GetPalestranteById',
      'Content-Type': 'application/json',
      token: null
    },
    body: body
  });
  this.retorno = res.json();

  return await (this.retorno);
}

async getPlanosByPublicoAlvo(){
  let body = JSON.stringify({ publico_alvo: 'ESPECTADORES' });

  const res = await fetch(environment.API_URL, {
    method: "POST",
    headers: {
      Accept: 'application/json',
      action: 'getPlanosByPublicoAlvo',
      'Content-Type': 'application/json',
      token: null
    },
    body: body
  });
  this.retorno = res.json();
  return await (this.retorno);
}

async appParticiparDaPalestra(token, id){
  let body = JSON.stringify({ id: id });

  let headers = new HttpHeaders()
    .set("Content-Type", "application/json")
    .set("Accept", "application/json")
    .set("Authorization", token);

  const result  = await this.http.post<any>(`${environment.API_V2_URL}app-espectador/palestras/participar/${id}`, body, { headers: headers }).toPromise();

  return result.body;
}

async payPalestra(usuario_id, palestra_id, nome, numero, codigo, mes, ano, valor, parcelas, id_cupom_desconto, cupom_tipo_desconto, cupom_valor_desconto, token){
  let body = JSON.stringify({
    usuario_id: usuario_id,
    palestra_id: palestra_id,
    nome: nome,
    numero: numero,
    codigo: codigo,
    mes: mes,
    ano: ano,
    valor: valor,
    parcelas: parcelas,
    id_cupom_desconto: '',
    cupom_tipo_desconto: '',
    cupom_valor_desconto: ''
   });


  const res = await fetch(environment.API_URL, {
    method: "POST",
    headers: {
      Accept: 'application/json',
      action: 'AppPayPalestraExtra',
      'Content-Type': 'application/json',
      token: token
    },
    body: body
  });
  this.retorno = res.json();
  return await (this.retorno);
}

async chargeMonthlyPlanOnCreditCard(plan_id, nome, numero, codigo, mes, ano, cupom, token) {
  let body = JSON.stringify({
    plan_id: plan_id,
    nome: nome,
    numero: numero,
    codigo: codigo,
    mes: mes,
    ano: ano,
    cupom: cupom
   });

  const res = await fetch(environment.API_URL, {
    method: "POST",
    headers: {
      Accept: 'application/json',
      action: 'ChargeMonthlyPlanOnCreditCard',
      'Content-Type': 'application/json',
      token: token
    },
    body: body
  });
  this.retorno = res.json();
  return await (this.retorno);
}

async getVerificaCadastro(email){
  let body = JSON.stringify({ email: email });

  const res = await fetch(environment.API_V2_URL+"app-espectador/auth/verifyIfEmailExist", {
    method: "POST",
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: body
  });
  this.retorno = res.json();
  return await (this.retorno);
}

async getPerfilEspectador(id_usuario){
  let body = JSON.stringify({ id_usuario });
  const res = await fetch(environment.API_V2_URL+"perfil/perfil-espectador", {
    method: "POST",
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: body
  });
  this.retorno = res.json();
  return await (this.retorno);
}

async login(email, senha){
  let body = JSON.stringify({
    email: email,
    password: senha,
    renomado: 0
   });

  const res = await fetch(environment.API_V2_URL+"app-espectador/auth/login", {
    method: "POST",
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: body
  });
  this.retorno = res.json();
  return await (this.retorno);
}

async buscaPalestrasInscricao(token){
  let body = JSON.stringify({ });

  const res = await fetch(environment.API_URL, {
    method: "POST",
    headers: {
      Accept: 'application/json',
      action: 'BuscaPalestrasInscricao',
      'Content-Type': 'application/json',
      token: token
    },
    body: body
  });
  this.retorno = res.json();
  return await (this.retorno);
}

async checkEmail(email){
  let body = JSON.stringify({ email: email });

  const res = await fetch(environment.API_URL, {
    method: "POST",
    headers: {
      Accept: 'application/json',
      action: 'AppIsEmailRegistered',
      'Content-Type': 'application/json',
      token: null
    },
    body: body
  });
  this.retorno = res.json();
  return await (this.retorno);
}

async createUser(nome, segmento, cep, perfil, ddd, telefone, email, password, assuntos,
                 renomado, empresa, origem, rg, cpf, cidade, estado, token){
  let body = JSON.stringify({
    nome: nome,
    segmento: segmento,
    cep: cep,
    perfil: perfil,
    ddd: ddd,
    telefone: telefone,
    email: email,
    password: password,
    conhecimentos: assuntos,
    renomado: renomado,
    empresa: empresa,
    tipo_pago_organico: null,
    origem: origem,
    rg: rg,
    cpf_cnpj: cpf,
    cidade: cidade,
    estado: estado
  });

  const res = await fetch(environment.API_URL, {
    method: "POST",
    headers: {
      Accept: 'application/json',
      action: 'CreateUser',
      'Content-Type': 'application/json',
      token: token
    },
    body: body
  });
  return await res.json();
}

async getBuscaCep(cep){
  let body = JSON.stringify({ cep: parseInt(cep) });

  const res = await fetch(environment.API_URL, {
    method: "POST",
    headers: {
      Accept: 'application/json',
      action: 'GetCidadeEstadoByCep',
      'Content-Type': 'application/json',
      token: null
    },
    body: body
  });
  this.retorno = res.json();
  return await (this.retorno);
}

async recoverAccountPassword(email, renomado){
  let body = JSON.stringify({ email: email, renomado: renomado });

  const res = await fetch(environment.API_URL, {
    method: "POST",
    headers: {
      Accept: 'application/json',
      action: 'RecoverAccountPassword',
      'Content-Type': 'application/json',
      token: null
    },
    body: body
  });
  this.retorno = res.json();
  return await (this.retorno);
}

inserirEspectadorGrupo = async (token, grupoId: number, espectadorId: number): Promise<any> => {
  let headers = new HttpHeaders()
    .set("Content-Type", "application/json")
    .set("Accept", "application/json")
    .set("Authorization", token);
    const body = JSON.stringify({ id_grupo: grupoId, espectadorId });
    const result = await this.http.post<any>(`${environment.API_V2_URL}dashboard/grupos/inserir-espectador-grupo`, body, { headers: headers }).toPromise();
    return result.body;
}

async getPalestraDisponivelBySlug(slug: string): Promise<any> {
  const res = await this.http.get<any>(`${environment.API_V2_URL}common/palestrasDisponiveis/${slug}`).toPromise();
  return res.body
}

  async GetPalestraDisponivelById(id_palestra: number) {
    let body = JSON.stringify({ id_palestra: id_palestra });

    const res = await fetch(environment.API_URL, {
      method: "POST",
      headers: {
        Accept: 'application/json',
        action: 'GetPalestraDisponivelById',
        'Content-Type': 'application/json',
        token: null
      },
      body: body
    });
    return await res.json();
  }

  async verificaOperadora(){
    let body = JSON.stringify({});

    const res = await fetch(environment.API_URL, {
      method: "POST",
      headers: {
        Accept: 'application/json',
        action: 'VerificaOperadora',
        'Content-Type': 'application/json',
        token: null
      },
      body: body
    });
    this.retorno = res.json();
    return await (this.retorno);
  }

  async getnetRecorrencia(token, plano_id, numero_cartao, card_name, security_code, expira_mes, expira_ano){
    let body = JSON.stringify({
      tipo: 'credito',
      recorrencia: true,
      plano_id: plano_id,
      numero_cartao: numero_cartao,
      card_name: card_name,
      security_code: security_code,
      expira_mes: expira_mes,
      expira_ano: expira_ano
    });

    const res = await fetch(environment.API_URL, {
      method: "POST",
      headers: {
        Accept: 'application/json',
        action: 'GetNet',
        'Content-Type': 'application/json',
        token: token
      },
      body: body
    });
    this.retorno = res.json();
    return await (this.retorno);
  }

  async getnetTransacao(token, numero_cartao, amount, card_name, security_code, expira_mes, expira_ano){
    let body = JSON.stringify({
      tipo: 'credito',
      recorrencia: false,
      numero_cartao: numero_cartao,
      amount: amount,
      card_name: card_name,
      security_code: security_code,
      expira_mes: expira_mes,
      expira_ano: expira_ano
    });

    const res = await fetch(environment.API_URL, {
      method: "POST",
      headers: {
        Accept: 'application/json',
        action: 'GetNet',
        'Content-Type': 'application/json',
        token: token
      },
      body: body
    });
    this.retorno = res.json();
    return await (this.retorno);
  }

  async usuarioPlano(token){
      let body = JSON.stringify({});

      const res = await fetch(environment.API_URL, {
        method: "POST",
        headers: {
          Accept: 'application/json',
          action: 'UsuarioPlano',
          'Content-Type': 'application/json',
          token: token
        },
        body: body
      });
      this.retorno = res.json();
      return await (this.retorno);
  }

  async participarPalestra(token, palestra_id){
    let body = JSON.stringify({
      id: palestra_id
    });

    const res = await fetch(environment.API_URL, {
      method: "POST",
      headers: {
        Accept: 'application/json',
        action: 'AppParticiparDaPlestra',
        'Content-Type': 'application/json',
        token: token
      },
      body: body
    });
    this.retorno = res.json();
    return await (this.retorno);

  }

  async getPalestraOnlinePSA(): Promise<any> {
    const result = await this.http.get<any>(`${environment.API_V2_URL}profissionais/new/palestra-psa`).toPromise();
    return result.body;
  }

  async hasSubscritpion(token, idPalestra): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Content-type', 'application/json');
    const body = { idPalestra: idPalestra, token: token }
    const result = await this.http.post<any>(`${environment.API_V2_URL}profissionais/espectadores/has-subscription`, body, { headers: headers }).toPromise();
    return result.body;
  }

  async getToken(idUsuario): Promise<any> {
    const result = await this.http.get<any>(`${environment.API_V2_URL}profissionais/auth/token/${idUsuario}`).toPromise();
    return result.body;
  }

  async getV2PalestraById(id): Promise<any> {
    const result = await this.http.get<any>(`${environment.API_V2_URL}profissionais/new/palestra/${id}`).toPromise();
    return result.body;
  }

  async getnetRec(body: object): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Content-type', 'application/json');
    let pbody = JSON.stringify(body)
    const result = await this.http.post<any>(`${environment.API_V2_URL}getnet/recorrencia/assinar-plano`, pbody, { headers: headers }).toPromise();
    return result.body;
  }

  async getnetCadastroCliente(body: object): Promise<any> {
    const result = await fetch(`${environment.API_V2_URL + "getnet/recorrencia/cadastro-cliente"}`,
      {
        method: "POST",
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body)
      })
    let res = await result.json()
    return res.body;
  }

  async loginUser(email, senha){
    let body = JSON.stringify({email: email, password: senha});

    const result = await fetch(`${environment.API_V2_URL}app-espectador/auth/login`, {
      method: "POST",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: body
    });

    return await result.json();
  }

  async saveProfile(body, token): Promise<any> {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("Accept", "application/json")
      .set('Authorization', token);

    let pbody     = JSON.stringify(body)
    const result  = await this.http.patch<any>(`${environment.API_V2_URL}app-espectador/profile`, pbody, { headers: headers }).toPromise();

    return result.body;
  }
}
