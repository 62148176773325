<div class="main__container">
  <div *ngFor="let profissional of speakers">
    <article
      (click)="navigateToRelacionadoProfile(profissional.identifier, profissional.id_tema)"
      class="profissional__relacionados__slide d-flex flex-column justify-content-between align-items-center text-center mb-5"
    >
      <div class="profissional__relacionados__slide__hero">
        <img
          class="profissional__relacionado__image"
          fill
          ngSrc="{{ profissional.image }}"
          alt="{{ 'Palestrante' + profissional.identifier }}"
        />
      </div>
      <h3 class="profissional__relacionados__slide__title">
        {{ profissional.nomeCompleto }}
      </h3>
      <p class="profissional__relacionados__slide__text">
        {{ getMinibio(profissional?.title) }}
      </p>
      <button class="custom__button">
        Perfil Completo
      </button>
    </article>
  </div>

</div>
