import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root'
})
export class ActiveCampaignService {
  constructor(public http: HttpClient) {}

  async sendLead({ name, email, phone }, utms: any) {
    const body = {
      name,
      email,
      phone,
      ...utms,
    };

    return this.http.post(`${environment.API_V2_URL}base/front/whatsapp-lead`, body);
  }
}
