<div class="modal__container">
  <img class="wpp__img" [src]="'assets/icons/modal-wpp.svg'" width="95" height="95" />
  <p>Quer falar com um dos nossos curadores agora? Preencha os campos abaixo e <b>converse conosco pelo WhatsApp!</b></p>
  <div>
    <div class="_form_244" id="form-active-campaign"></div>
  </div>
  <!-- <form
    [formGroup]="contactForm"
    class="forms__container d-flex flex-column justify-content-center p-3"
  >
    <mat-form-field appearance="outlined" class="input__container" >
      <input matInput placeholder="Digite seu Nome" name="name" [formControlName]="'name'" [type]="'text'" />
    </mat-form-field>
    <mat-form-field appearance="outlined" class="input__container">
      <input matInput placeholder="Digite seu E-mail" name="email" [formControlName]="'email'" [type]="'text'" />
    </mat-form-field>
    <mat-form-field appearance="outlined" class="input__container flex justify-content-center align-items-center">
      <input
        matInput
        placeholder="Digite seu Telefone"
        name="phone"
        [formControlName]="'phone'"
        [type]="'text'"
        [mask]="'(00) 0 0000-0000||(00) 0000-0000'"
      />
      <mat-error *ngIf="contactForm.controls.phone.invalid">{{ getErrorMessage() }}</mat-error>
    </mat-form-field>

    <button
      class="send__button"
      type="submit"
      (click)="sendLead()"
    >
      Falar no WhatsApp
    </button>
  </form> -->
</div>
