import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { of } from "rxjs";
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export abstract class AbstractService {
  private data;
  private observable;
  private headers: HttpHeaders;


  constructor(public http: HttpClient, headers: HttpHeaders) {
      this.headers = headers;
  }


  // constructor(public http: HttpClient) {}

  getContent(body: object) { 
    if (this.data) {
      return of(this.data);
    } else if (this.observable) {
      return this.observable;
    } else {
      const endpont = `${environment.API_URL}`;

      this.observable = this.http.post(endpont, body, {
        headers: this.headers,
        observe: 'response'
      })
      .pipe(
        map(response =>  {
          this.observable = null;
          if (response.status === 400) {
            return 'Request failed.';
          } else if (response.status === 200) {
            this.data = response.body;
            return this.data;
          }
        })
      )

      return this.observable;
    }
  }

}
