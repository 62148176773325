import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'psa-not-implement',
  templateUrl: './not-implement.component.html',
  styleUrls: ['./not-implement.component.css']
})
export class NotImplementComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
