import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { AbstractService } from './abstract.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AssuntosService  extends AbstractService {

  constructor(public http: HttpClient) {
    super(http, new HttpHeaders({'action': 'GetAssuntosList'}));
  }


  async getTemaByName(assunto_nome: string): Promise<any> {
    const res = await this.http.get<any>(`${environment.API_V2_URL}common/especialistasPorTema/${assunto_nome}`).toPromise();
    return res.body
  }

  async getTemas(has_filter: boolean, id_pai: number, search: string): Promise<any> {
    let search_param = `?pai=${id_pai}&text=${search.toLowerCase().trim()}`
    const res = await this.http.get<any>(`${environment.API_V2_URL}common/assuntos/${has_filter ? 1 : 0}${search_param}`).toPromise();
    return res.body
  }

  async getFormatos(): Promise<Array<any>> {
    const result = await this.http.get<any>(`${environment.API_V2_URL}common/formatos`).toPromise();
    return result.body;
  }

  getAssuntosRelacionadas(idAssunto, assuntos) {
    return assuntos.filter(item => item.id_assunto_pai === idAssunto);
  }

  slugify(text) {
    const from = "ãàáäâẽèéëêìíïîõòóöôùúüûñç·/_,:;"
    const to = "aaaaaeeeeeiiiiooooouuuunc------"

    const newText = text.split('').map(
      (letter, i) => letter.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i)))

    return newText
      .toString()                     // Cast to string
      .toLowerCase()                  // Convert the string to lowercase letters
      .trim()                         // Remove whitespace from both sides of a string
      .replace(/[àÀáÁâÂãäÄÅåª]+/g, 'a')       // Special Characters #1
      .replace(/[èÈéÉêÊëË]+/g, 'e')       	// Special Characters #2
      .replace(/[ìÌíÍîÎïÏ]+/g, 'i')       	// Special Characters #3
      .replace(/[òÒóÓôÔõÕöÖº]+/g, 'o')       	// Special Characters #4
      .replace(/[ùÙúÚûÛüÜ]+/g, 'u')       	// Special Characters #5
      .replace(/[ýÝÿŸ]+/g, 'y')       		// Special Characters #6
      .replace(/[ñÑ]+/g, 'n')       			// Special Characters #7
      .replace(/[çÇ]+/g, 'c')       			// Special Characters #8
      .replace(/[ß]+/g, 'ss')       			// Special Characters #9
      .replace(/[Ææ]+/g, 'ae')       			// Special Characters #10
      .replace(/[Øøœ]+/g, 'oe')       		// Special Characters #11
      .replace(/[%]+/g, 'pct')       			// Special Characters #12
      // .replace(/\s+/g, '-')           		// Replace spaces with -
      .replace(/[^\w\-]+/g, '')       		// Remove all non-word chars
      // .replace(/\-\-+/g, '-')         		// Replace multiple - with single -
      .replace(/^-+/, '')             		// Trim - from start of text
      .replace(/-+$/, '');
  }

  async getEspecialistasByTema(id: number) {
    let body = JSON.stringify({ assuntos_id: id });

    const res = await fetch(environment.API_URL, {
      method: "POST",
      headers: {
        Accept: 'application/json',
        action: 'GetEspecialistasByTema',
        'Content-Type': 'application/json',
        token: null
      },
      body: body
    });
    return await res.json();
  }
}
